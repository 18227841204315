<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
      <!-- <el-form-item label="文章分类" prop="dynamicId">
        <el-select v-model="ruleForm.dynamicId" placeholder="请选择">
          <el-option
            v-for="(item, index) in ['1']"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="展示图片" prop="photos">
        <div style="width:350px;">
          <el-upload :action="$store.state.uploadingUrl" class="iconUpload" list-type="picture-card"
            :class="{ disabled: uploadDisabled }" :limit="18" :on-progress="handProgress" :on-success="handleSuccess"
            :on-remove="handleRemove" multiple :file-list="fileList">
            <i class="el-icon-plus"></i>
          </el-upload>
          <div class="h100p d-flex align-items-center">
            <p>建议上传150x150，格式可为png、jpg或jpeg，最多9张</p>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="动态内容" prop="title">
        <el-input type="textarea" placeholder="请输入动态内容" rows="8" v-model="ruleForm.title" maxlength="200"
          show-word-limit>
        </el-input>
      </el-form-item>
      <!-- <el-form-item label="是否发布" prop="isDrafts">
        <el-switch v-model="ruleForm.isDrafts" active-text="发布" inactive-text="关闭" />
        <span class="ml10" style="vertical-align: middle;color: rgb(253, 99, 78);">关闭状态即保存到"草稿箱"</span>
      </el-form-item>
      <el-form-item label="是否置顶" prop="isTop">
        <el-switch v-model="ruleForm.isTop" active-text="是" inactive-text="否" />
      </el-form-item> -->
    </el-form>
  </div>
</template> 

<script>
export default {
  data() {
    var valiIcon = (rule, value, callback) => { // 图片验证
      if (!this.ruleForm.photos) {
        callback();
      } else {
        callback();
      }
    }
    return {
      disabled: false,
      ruleForm: {
        title: "",
        originAuthor: "",
        dynamicId: "",
        author:"",
        details: "",
        isDrafts: false,
        photos: "",
      },
      fileList: [],
      photosData: [],
      rules: {
        title: [{ required: true, message: "请输入动态内容", trigger: "blur" }],
        photos: [{ required: true, }],
      },
    };
  },
  computed: {
    uploadDisabled() {
      return this.photosData.length >= 9;
    },
  },
  methods: {
    handleRemove(file, fileList) {
     //-console.log(file, fileList);
      let photosData = [];
      for (let row of fileList) {
        if ("response" in row.data) {
          photosData.push(`https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${response.data}`);
        } else {
          photosData.push(row.url);
        }
      }
      this.photosData.push(fileList);
    },
    handProgress(event, file, fileList) {

    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.photosData.push(`https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${response.data}`);
    },
    submitForm(payload) {

      if (this.photosData.length == 0) {
        return this.$message.error('请上传文章封面')
      }
      let data = this.ruleForm;
      data.photos = this.photosData.join(",")
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          data.isDrafts = 1;
          data.isTop = data.isTop ? 1 : 0;
          // if(payload) data.isDrafts = 0 ;
          this.$emit("submitForm", data);
        } else {
          //-console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>